<template>
  <div class="changePswd">
    <el-image
      class="changePswd_bg"
      :src="require('./../../public/img/login/login_bg.png')"
      fit="cover"
    >
    </el-image>
    <div class="changeBox">
      <el-steps class="steps" :active="step" simple>
        <el-step title="1.填写手机号码或邮箱"></el-step>
        <el-step title="2.完善信息"></el-step>
        <el-step title="3.注册成功"></el-step>
      </el-steps>
      <el-form class="formBox" :model="formData" :rules="rules" ref="formData">
        <template v-if="step === 1">
          <el-form-item
            class="formItem"
            prop="phone"
            :label="formInfo.account.label"
            label-width="100px"
          >
            <el-input
              class="input"
              v-model="formData.phone"
              :placeholder="formInfo.account.placeholder"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItem"
            prop="captcha"
            :label="formInfo.Captcha.label"
            label-width="100px"
          >
            <div class="codeBox">
              <el-input class="input" v-model="formData.captcha"></el-input>
              <div @click="refreshCode" class="codeimg">
                <s-identify :identifyCode="identifyCode"></s-identify>
              </div>
              <!-- <el-button @click="refreshCode" type="text" class="btn"
              >看不清验证码</el-button
            > -->
            </div>
          </el-form-item>
          <el-form-item
            class="formItem"
            prop="code"
            :label="formInfo.code.label"
            label-width="100px"
          >
            <div class="codeBox">
              <el-input
                class="input"
                v-model="formData.code"
                :placeholder="formInfo.code.placeholder"
              ></el-input>
              <el-button
                class="btn"
                @click="getCode(eventType)"
                type="primary"
                plain
                :disabled="isDisabled"
                >{{
                  isDisabled
                    ? countText.count + i18nData.changecode
                    : i18nData.code
                }}</el-button
              >
            </div>
          </el-form-item>
          <!-- <el-tabs class="typeTabs" v-model="activeType" @tab-click="handleClick">
          <el-tab-pane :label="i18nData.typephone" name="phone"></el-tab-pane>
          <el-tab-pane :label="i18nData.typeemail" name="email"></el-tab-pane>
        </el-tabs>
        <template v-if="activeType == 'phone'">
          <el-form-item class="formItem" prop="phone">
            <el-input
              class="input"
              v-model="formData.phone"
              :placeholder="i18nData.phonetips"
              ><template slot="prefix">+86</template></el-input
            >
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item class="formItem" prop="email">
            <el-input
              class="input"
              v-model="formData.email"
              :placeholder="i18nData.emailtips"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item class="formItem" prop="password">
          <el-input
            class="input"
            v-model="formData.password"
            :placeholder="i18nData.pswtips"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item> -->
          <el-button
            class="submitBtn"
            type="primary"
            @click="nextStep('formData')"
            >{{ btnGroup.nextBtn }}</el-button
          >
        </template>
        <template v-if="step === 2">
          <el-form-item
            class="formItem"
            prop="colleges"
            :label="formInfo.colleges.label"
            label-width="100px"
            required
          >
            <el-input
              class="input"
              v-model="formData.colleges"
              :placeholder="formInfo.colleges.placeholder"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItem"
            prop="password"
            :label="formInfo.password.label"
            label-width="100px"
            required
          >
            <el-input
              class="input"
              v-model="formData.password"
              :placeholder="formInfo.password.placeholder"
              autocomplete="off"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItem"
            prop="nickname"
            :label="formInfo.nickname.label"
            label-width="100px"
            required
          >
            <el-input
              class="input"
              v-model="formData.nickname"
              :placeholder="formInfo.nickname.placeholder"
            ></el-input>
          </el-form-item>
          <userAgreement @chooseAgreement="chooseAgreement"></userAgreement>
          <el-button
            class="submitBtn"
            type="primary"
            @click="submitForm('formData')"
            >{{ btnGroup.submitBtn }}</el-button
          >
        </template>
        <template v-if="step === 3">
          <div class="registerSuccess">
            <div class="registerSuccessImg">
              <el-image
                class="bg-img"
                style="width: 100%; height: 100%;;"
                :src="require('./../../public/img/login/register_success.png')"
                fit="cover"
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="registerSuccessText">
              <span>恭喜您，注册成功。</span>
              <span>您将拥有下载、上传资料的权限。</span>
            </div>
          </div>
          <el-button class="submitBtn" type="primary" @click="toLogin()">{{
            btnGroup.successBtn
          }}</el-button>
        </template>
        <div class="toOther">
          <span @click="toPage(1)">{{ i18nData.forgetpw }}</span>

          <span @click="toPage(0)">{{ i18nData.loginup }}</span>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  loginfunc,
  checkAgreement,
  getcodefunc
} from "./../common/top.mixin.js";
import userAgreement from "./../components/userAgreement.vue";
import SIdentify from "./../components/canvas.vue";

export default {
  name: "register",
  mixins: [loginfunc, checkAgreement, getcodefunc],
  components: {
    userAgreement,
    SIdentify
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        //  console.log("rule里调用this",this.i18nData)
        return callback(new Error("账号不能为空"));
      } else {
        const reg1 = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        const reg2 = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
        if (!reg1.test(value) && !reg2.test(value)) {
          return callback(new Error("请输入正确的账号"));
        } else {
          callback();
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        // if (this.formData.password !== "") {
        //   this.$refs.formData.validateField("password");
        // }
        callback();
      }
    };
    return {
      activeType: "phone",
      eventType:"register",
      // 步骤
      step: 1,
      formInfo: {
        account: {
          label: "输入账号",
          placeholder: "输入手机号或者邮箱"
        },
        Captcha: {
          label: "图形验证码"
        },
        code: {
          label: "输入验证码",
          placeholder: "输入手机或邮箱验证码",
          button: "获取验证码"
        },
        colleges: {
          label: "企业院校",
          placeholder: "输入您所在企业或者院校"
        },
        password: {
          label: "账号密码",
          placeholder: "输入账号密码"
        },
        nickname: {
          label: "联系人姓名",
          placeholder: "输入联系人姓名"
        }
      },
      btnGroup: {
        nextBtn: "下一步",
        submitBtn: "提交",
        successBtn: "完成"
      },
      // 验证码参数
      identifyCodes: "1234567890",
      identifyCode: "",
      formData: {
        phone: "",
        email: "",
        captcha: "",
        code: "",
        colleges: "",
        password: "",
        nickname: ""
      },
      rules: {
        phone: [{ validator: checkPhone, trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur"
          }
        ],
        captcha: [
          { required: true, message: "请输入图形验证码", trigger: "blur" }
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        colleges: [
          { required: true, message: "请输入企业院校", trigger: "blur" }
        ],
        nickname: [{ required: true, message: "请输入联系人姓名", trigger: "blur" }]
      }
    };
  },
  mounted() {
    // 验证码初始化
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  created() {
    this.$store.state.activeIndex = "";
    this.$store.state.myActiveIndex = this.$route.name;
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.$refs["formData"].clearValidate();
    },
    nextStep(formName) {
      if (this.identifyCode != this.formData.captcha) {
        this.$message({
          message: "图形验证码不正确",
          type: "error",
          offset: 40
        });
        return;
      }
      this.$refs[formName].validate(async valid => {
        console.log(valid);
        if (valid) {
          this.step = 2;
        } else {
          return;
        }
      });
    },
    toLogin() {
      // 跳转登录页面
      this.success(2);
    },
    submitForm(formName) {
      // 判断是否勾选协议
      if (!this.checkChoose()) {
        return;
      }
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let params = {
            username:
              this.activeType == "phone"
                ? this.formData.phone
                : this.formData.email,
            code: this.formData.code,
            bio: this.formData.colleges,
            password: this.formData.password,
            nickname: this.formData.nickname
          };
          if (this.activeType == "phone") {
            params.mobile = this.formData.phone;
          } else {
            params.email = this.formData.email;
          }
          let res = await this.$api.https.goRegister(params);
          if (!res.code) {
            this.$message({
              message: res.msg,
              type: "error",
              offset: 40
            });
            return;
          }
          this.$store.state.vuex_token = res.data.token;
          this.step = 3;
          // this.$router.push({ path: "/my" });
        } else {
          this.$message({
            message: "登录验证失败",
            type: "error",
            offset: 40
          });
          return false;
        }
      });
    },
    toPage(index) {
      let pageArr = ["/login", "/forgetpwd"];
      this.$router.replace({ path: pageArr[index] });
    },
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
      console.log(this.identifyCode);
    }
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #0495f3;
.changePswd {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  text-align: left;
  .changeBox {
    box-sizing: border-box;
    padding: 20px 0 40px;
    border: 1px solid #dcdcdc99;
    .steps {
      width: 80%;
      box-sizing: border-box;
      padding: 10px 2%;
      margin-bottom: 40px;
      ::v-deep .el-step.is-simple .el-step__head {
        display: flex;
      }
      ::v-deep .el-step.is-simple .el-step__title {
        font-size: 14px;
        line-height: 24px;
      }
      ::v-deep .el-step.is-simple:not(:last-of-type) .el-step__title {
        max-width: 90%;
      }
    }
    .formBox {
      height: 100%;
      width: 100%;
      padding: 0 20%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .typeTabs {
        margin-bottom: 10px;
      }
      ::v-deep.el-tabs__nav-wrap::after {
        background-color: transparent;
      }
      .formItem {
        // margin-bottom: 5px;
        width: 100%;
        .input {
          width: 100%;
        }
        .codeBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .input {
            width: 49%;
          }
          .btn {
            width: 49%;
            background-color: #ebebeb;
            border-color: #ebebeb;
            border-radius: 0px;
            color: #000000 !important;
          }
        }

        ::v-deep.el-input__inner {
          border-radius: 2px;
          background: #ebebeb;
        }
      }
      .submitBtn {
        background-color: $vue_color;
        border-color: $vue_color;
        height: 36px;
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        border-radius: 2px;
      }
      .toOther {
        margin-top: 20px;
        font-size: 13px;
        width: 280px;
        display: flex;
        justify-content: space-between;
        span {
          margin-left: 10px;
          color: $vue_color;
          cursor: pointer;
        }
      }
      .registerSuccess {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5rem;
        .registerSuccessImg {
          width: 20%;
        }
        .registerSuccessText {
          margin-top: 0.3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 0.9rem;
          > span:nth-child(1) {
            color: $vue_color;
          }
        }
      }
    }
  }
}
</style>
